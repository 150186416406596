@import '~@angular/material/theming';

@include mat-core();

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/lato/Lato-Regular.ttf);
}

// custom background and foreground palettes
$care-central-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover: rgba(black, 0.04),
  // TODO(kara): check style with Material Design UX card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200),
);

$care-central-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  hint-text: $dark-disabled-text,
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// instead of creating a theme with mat-light-theme like a sane person, we will create our own theme-creating function that lets us apply our own foreground and background palettes.
@function create-care-central-theme(
  $primary,
  $accent,
  $warn: mat-palette($mat-blue)
) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $care-central-foreground,
    background: $care-central-background
  );
}

// The rest of this is like a normal theme.scss file. The only difference being that we use create-wonky-theme instead of mat-light-theme
$custom-theme-primary: mat-palette($mat-light-blue);
$custom-theme-accent: mat-palette($mat-blue);
$custom-theme-warn: mat-palette($mat-red);

$custom-theme: create-care-central-theme(
  $custom-theme-primary,
  $custom-theme-accent,
  $custom-theme-warn
);

$custom-typography: mat-typography-config(
  $font-family: 'Lato',
);
@include mat-core($custom-typography);

@include angular-material-theme($custom-theme);
