@import 'theme.scss';
@import '_variables';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~quill/dist/quill.snow.css';

//? I don't like this, but you have to do this
//? in the global styles.scss
//? in order for mat-tooltip to be multiline.
//? Check member-appointment-calendar and supervisorneeded-member-icon
//? for implementation details.
.multi-line-tooltip {
  white-space: pre-line;
  font-size: small;
}
.multi-line {
  white-space: pre-line;
  font-weight: bold;
  background-color: white;
  font-family: $ch-font-family;
  color: black !important;
  font-size: small;
  line-height: 1.2em;
  position: relative;
  top: -10px;
  width: 149px;
  border-width: 1px, 0px, 0px, 1px;
  border-color: $ch-v2-gray-shade;
  box-shadow: 1px 2px 2.5px 1px $ch-v2-misty-gray;
}
// Global Angular Material Overrides
//
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/material-icons/MaterialIcons-Regular.woff2)
    format('woff2');
}

.ch-modal-dialog {
  z-index: -1;
  cursor: pointer;
}

.unhealthy-text {
  color: $ch-unhealthy-status;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.mat-form-field-prefix {
  font-size: $ch-font-size-small;
}

.mat-form-field-label {
  font-size: $ch-font-size-tiny;
}

.mat-form-field-label > mat-label {
  color: $ch-label;
  position: relative;
  top: -4px;
}

.mat-form-field-infix {
  padding-top: 0px !important;
}

.mat-tab-label,
.mat-tab-link {
  font-size: $ch-font-size-small !important;
  line-height: 14px !important;
  color: $ch-dark-etchings !important;
}

.mat-drawer-container {
  background-color: $ch-canvas-background !important;
  position: fixed !important;
}

.mat-tab-body-wrapper {
  margin-top: 0.5em;
}

.mat-tab-label-content > * {
  font-size: $ch-font-size-small;
}

button > mat-icon {
  color: $ch-highlight-etchings;
}

.mat-drawer-content {
  overflow-x: hidden !important;
}
// Global Element Overrides
//
body {
  font-family: Lato, Roboto, 'Helvetica Neue', sans-serif;
  color: $ch-font-color;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

input,
textarea,
select,
button {
  font-family: Lato, Roboto, 'Helvetica Neue', sans-serif;
}

// Shared Custom Classes
//
.ch-hidden {
  display: none;
}
.ch-optional-host-container {
  padding: 1em;
}

.ch-clickable {
  cursor: pointer;
}

.ch-warning {
  color: $ch-error;
  font-size: $ch-font-size-small;
}

// Status Elements
$ch-status-complete: $ch-font-color;
$ch-status-active: $ch-label;
$ch-status-first-review: $ch-healthy-status;
$ch-status-second-review: $ch-standout-active;
$ch-status-inactive: $ch-disabled-dark;
$ch-status-unset: $ch-error;
$ch-status-first-step: $ch-healthy-status;
$ch-status-second-step: $ch-standout-active;
$ch-status-third-step: $ch-highlight-active;

.ch-status-unset {
  color: $ch-status-unset;
}
.ch-status-unset.inverted {
  background-color: $ch-status-unset;
  color: $ch-canvas-background;
}

.ch-status-complete {
  color: $ch-status-complete;
}
.ch-status-complete.inverted {
  background-color: $ch-status-complete;
  color: $ch-canvas-background;
}

.ch-negative-currency {
  font-style: italic;
  color: $ch-unhealthy-status;
}

.ch-status-active {
  color: $ch-status-active;
}
.ch-status-active.inverted {
  background-color: $ch-status-active;
  color: $ch-canvas-background;
}

.ch-status-first-review {
  color: $ch-status-first-review;
}
.ch-status-first-review.inverted {
  background-color: $ch-status-first-review;
  color: $ch-canvas-background;
}

.ch-status-second-review {
  color: $ch-status-second-review;
}
.ch-status-second-review.inverted {
  background-color: $ch-status-second-review;
  color: $ch-canvas-background;
}

.ch-status-inactive {
  color: $ch-status-inactive;
}

.ch-status-inactive.inverted {
  background-color: $ch-status-inactive;
  color: $ch-canvas-background;
}

// A flex box based grid. Renders immediate children in a grid pattern,
// left to right, and wrapping to the next row based on needed space.
//
// Use this class to create a grid pattern for the child components.
// if the child is not a div or component, wrap in a div for consistent styling
//
// if different sizing is needed, define and set these variables
// --flex-column-gap: 8px; // horizontal gap between columns
// --flex-row-gap: 8px; // vertical gap between rows
// --flex-min-width: 200px; // min item width, static
// --flex-max-width: 400px; // max item width, static
// --flex-columns: 4; // number of columns in the grid view
//
// .ch-grid-fill          - max width is ignored, and grid items expand to fill available space
// > *                    - child items styled as grid members
// > .ch-grid-item-fill   - this item, and only this one, should expand to fill available space
// > .ch-grid-item-double - this item should take the width of 2 items
//
// Markup:
// <div class="ch-flex-grid">
//  <div><p>a normal member</p></div>
//  <div class="ch-grid-item-double"><p>a double width member</p></div>
//  <custom-angular-component></custom-angular-component>
// </div>
.ch-flex-grid {
  --gap-default: 8px;
  --flex-width: calc(
    calc(100% / var(--flex-columns, 4)) -
      var(--flex-column-gap, var(--gap-default))
  ); // item width as percent of parent, ideally fraction of 1 like 1/4
  display: flex;
  width: calc(100% + var(--flex-column-gap, var(--gap-default)));
  // item --gap margins are on the top and left
  // set negative to make items line up with edge of container
  margin-top: calc(-1 * var(--flex-row-gap, var(--gap-default)));
  margin-right: 0;
  margin-bottom: 0;
  margin-left: calc(-1 * var(--flex-column-gap, var(--gap-default)));
  vertical-align: middle;
  height: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;

  // all immediate children - flex items
  // should be form inputs
  > *,
  > .ch-grid-item {
    --flex-col-width: 1;
    --flex-incl-gap: calc(
      calc(var(--flex-col-width) - 1) *
        var(--flex-column-gap, var(--gap-default))
    );
    flex-shrink: 1;
    justify-self: stretch;
    align-self: center;
    flex-basis: calc(
      var(--flex-incl-gap) + calc(var(--flex-col-width) * var(--flex-width))
    );
    min-width: calc(
      var(--flex-incl-gap) +
        calc(var(--flex-col-width) * var(--flex-min-width, 200px))
    );
    max-width: calc(
      var(--flex-incl-gap) +
        calc(var(--flex-col-width) * var(--flex-max-width, 400px))
    );
    margin-top: var(--flex-row-gap, var(--gap-default));
    margin-right: 0;
    margin-bottom: 0;
    margin-left: var(--flex-column-gap, var(--gap-default));
    padding: 0;
    border-left: 0;
    border-right: 0;

    &.ch-grid-item-fill {
      max-width: unset;
      flex-grow: var(--flex-col-width);
    }

    &.ch-grid-item-double {
      --flex-col-width: 2;
    }

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &.ch-grid-fill {
    > * {
      max-width: unset;
      flex-grow: var(--flex-col-width);
    }
  }
}

// add class to mat form fields to render for the smart-list filter (no border, underline, 2px gap for text
.ch-use-filter-styles {
  &.ch-compact {
    .mat-form-field-wrapper {
      padding-bottom: 0.34375em;
    }
    .mat-form-field-infix {
      padding-bottom: 0.34375em;
    }
    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: unset;
    }
  }
  &.mat-form-field-appearance-outline {
    // fix spacing
    .mat-form-field-wrapper {
      margin: 0;
    }
    .mat-form-field-flex {
      padding: 0 0 2px 0;
    }
    .mat-form-field-infix {
      border-top: unset;
    }
    // gets rid of the border ring and instead applies the 1px underline
    .mat-form-field-outline,
    .mat-form-field-outline.mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-radius: unset;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: $ch-line-drawing;
        border-width: 1px;
      }
    }
    .mat-select-placeholder,
    ::placeholder {
      @include filter-placeholder-text;
    }
  }
}
// !important: this is a global style for all grids
// so don't make any assumptions about the numbers of columns.
.ch-search-container {
  background-color: $ch-canvas-background-medium;
  vertical-align: middle;
  padding-bottom: 4px;

  & > div {
    display: flex;
    position: relative;
    background-color: $ch-canvas-background;
    margin-top: 8px;

    mat-icon {
      justify-self: start;
      align-self: center;
      margin: 0;
      border: 0;
      padding: 0;
      padding-top: 4px;
      padding-left: 4px;
      font-weight: bold;
      font-size: $ch-font-size-large;
    }

    input {
      justify-self: stretch;
      align-self: center;
      padding: 0;
      border: 0;
      background-color: $ch-canvas-background;
      font-size: $ch-font-size-small;
      border-bottom: 1px $ch-line-drawing solid;
      margin-left: 2px;
      margin-right: 2px;
    }

    input:focus {
      outline: none;
    }
  }
}

h2.title {
  font-size: $ch-font-size-small;
  font-weight: bold;
  color: $ch-highlight-etchings;
  padding-bottom: 0;
  margin-bottom: 0;
}

.mat-input-element:disabled,
.mat-select-disabled,
.mat-select-value-text {
  color: black;
}

.mat-dialog-container {
  font-size: 16px;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px !important;
  overflow: hidden;
}

.custom-dialog-container .mat-dialog-title {
  margin: 0px;
}
.custom-dialog-container .mat-dialog-content {
  margin: 0px;
}

.mat-dialog-container .mat-dialog-title.cdk-drag-handle {
  cursor: move;
}

ch-smart-field,
ch-auto-complete-field {
  padding-right: 3px;
}

ch-smart-field[type='checkbox'] .mat-form-field-outline-start,
ch-smart-field[type='checkbox'] .mat-form-field-outline-end {
  border: 0 !important;
}

.ch-required-star {
  font-size: $ch-font-size-xlarge;
  color: red;
}

.ch-small-error-message {
  font-size: $ch-font-size-small;
  color: $ch-error;
}

span.ch-clickable mat-icon:hover {
  color: yellow;
}

// the default mat options are too large
.cdk-overlay-container mat-option.txt-small {
  font-size: $ch-font-size-small;
}

.mat-paginator.infinite .mat-paginator-range-label {
  display: none;
}

div#procedure-dialog-header-close {
  margin-bottom: 20px !important;
  font-weight: 900 !important;
}
