/* custom-survey-styles */
.sd-btn {
  border-radius: 25px;
}

.sd-navigation__next-btn {
  color: white;
  background-color: #4d857b !important;
  width: 130px;
}

.sd-navigation__prev-btn {
  margin-right: 10px;
  color: var(--sjs-primary-backcolor);
}

.sd-navigation__complete-btn {
  color: var(--sjs-primary-forecolor);
  background-color: #4d857b !important;
}

