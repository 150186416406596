// Import material theming functions
@import '~@angular/material/theming';
// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat-palette($mat-blue);
$app-accent: mat-palette($mat-light-blue);
$app-warn: mat-palette($mat-red);
// Create your Sass color vars (will be available in all the project)
$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);

$ch-highlighter: #faca83;
$ch-highlighter-disabled: #bdbdbd;

$ch-highlight-valid: rgb(60, 170, 247);
$ch-standout-active: #eb7b15;
$ch-highlight-active: #a0ff9a;
$ch-standout-inactive: rgba(#2386e1, 0.12);
$ch-font-family: Arial;
$ch-font-color: black;
$ch-font-etchings: #979797;

$ch-border-medium: rgba(0, 0, 0, 0.35);

$ch-disabled-light: #e3f2fd;
$ch-disabled-dark: rgba(138, 138, 138, 0.75);

$ch-highlight-etchings: #2386e1;

$ch-line-drawing: rgba(138, 138, 138, 0.75);

$ch-readonly-background-color: rgb(235, 235, 228);

$ch-light-etchings: #d9d9d9;
$ch-dark-etchings: black;

$ch-canvas-background: white;
$ch-canvas-background-light: #e3f2fd;
$ch-canvas-background-medium: #bbddfa;

$ch-canvas-sidebar: #d0d0d0;

$ch-healthy-status: #009d48;
$ch-neutral-status: black;
$ch-unhealthy-status: red;

$ch-check-mark: rgba(0, 207, 83, 0.8);
$ch-x-mark: rgba(255, 0, 0, 0.8);

$ch-error: rgba(255, 0, 0, 0.8);
$ch-error-background: rgb(255, 160, 160);

$ch-label: #1e74ce;
$ch-link: #2386e1;

$ch-notes-panel: #f7f7f7;

$ch-dialog-color: #f1f1f1;

$ch-font-size-jumbo-icon: 42px;
$ch-font-size-xlarge: 18px;
$ch-font-size-large: 16px;
$ch-font-size-medium: 14px;
$ch-font-size-small: 12px;
$ch-font-size-tiny: 11px;
$ch-font-size-teeny: 8px;

$ch-border: 1px solid $ch-line-drawing;

.ch-error-bubble {
  position: relative;
  background-color: $ch-canvas-background;
  opacity: 1;
  z-index: 600;
  border: 1px solid $ch-error;
  padding-left: 1em;
  border-radius: 3px;
}

@mixin filter-placeholder-text {
  color: $ch-font-etchings;
  opacity: 1;
}

// Rebrand related styles
$ch-v2-orange: #e77d6a;
$ch-v2-medium-green: #377277;
$ch-v2-pale-green: #e1eeea;
$ch-v2-pale-blue: #eef6fb;
$ch-v2-dark-green: #165375;
$disabled-link-color: #888888;
$ch-label-val-color: #333333;
$ch-thick-label: #111111;
$ch-label-color: #666666;
$ch-v2-white: #ffffff;
$ch-v2-disable: #adadad;
$ch-v2-light-gray: #c7c7c7;
$ch-v2-dark-gray: #333333;
$ch-v2-gray-shade: #adadad33;
$ch-v2-light-gray-shade: #eeeeee;
$ch-v2-dark-pink-shade: #bc204b;
$ch-v2-misty-gray: #c7c7c780;
$ch-sky-blue-mist: #e5f1f9;
$ch-cloud-gray: #e8e8e8;
$ch-v2-pale-grey: #ecedee;
$ch-v2-pink-shade: #f8d8d2;
$ch-v2-transparent-black: #0000000a;
$ch-v2-steel-blue: #3d7da6;
$srv-interactive-component-color: #165375;
$srv-color-white: #ffffff;
$srv-heading-color: #444444;
$survey-creator-border: 1px #f5f5f5 solid;
